<template>
  <div :class="gridClass" class="mb-4">
    <div class="project-list-post pb-4" :class="display_list">
      <!--image-->
      <router-link :to="{ path: 'products/' + project_data.slug }" replace>
        <div class="thumable p-2 position-relative">
          <div
            class="
              project-options-box
              position-absolute
              bottom-0
              mb-4
              ms-4
              d-flex
              align-items-center
            "
          >
            <div
              v-if="project_data.is_completed"
              class="
                donation_done
                edc-gradient-green
                rounded-pill
                text-white
                fs-7
              "
            >
              <i class="fas fa-check"></i>

              {{ $t("btn.donated") }}
            </div>
            <div
              v-if="project_data.is_approved_zaka"
              class="
                zakat_accept
                edc-gradient-blue
                rounded-pill
                text-white
                fs-7
              "
            >
              <i class="fas fa-check"></i>
              {{ $t("btn.zakat_is_permissible") }}
            </div>
            <!-- is alradey flowin us class btn-fllowed-->
            <button
              v-if="!project_data.is_wishlist"
              :data-id="project_data.id"
              @click.prevent="followProject()"
              class="
                follow
                btn-fllowing
                edc-gradient-yellow
                rounded-pill
                text-white
                fs-7
              "
            >
              <i class="far fa-heart"></i>
              {{ $t("btn.follow") }}
            </button>
            <button
              v-if="project_data.is_wishlist"
              :data-id="project_data.id"
              @click.prevent="followProject()"
              class="
                follow
                edc-gradient-yellow
                rounded-pill
                text-white
                fs-7
                btn-fllowed
              "
            >
              <i class="far fa-heart"></i>
              {{ $t("btn.un_follow") }}
            </button>
          </div>
          <img
            :src="project_data.image.medium_large"
            lazysizes
            :data-src="project_data.image.medium_large"
            class="project-list-img img-fluid"
            :alt="project_data.title"
            :title="project_data.title"
            width="432"
            height="384"
            :srcset="project_data.image.medium_large"
          />
        </div>
      </router-link>
      <!--//image-->
      <div class="project-content-center flex-fill">
        <!--title-->
        <h1 class="project-list-title fs-5 fw-bold p-2 mb-3">
          <router-link
            :to="{ path: 'products/' + project_data.slug }"
            :title="project_data.title"
            class="text-truncate d-block"
            >{{ project_data.title }}</router-link
          >
        </h1>
        <!--//title-->

        <!--progress-->

        <progress-bar v-if="project_data.meta && parseInt(project_data.meta.target) > 0" :percentage="project_data.meta.percent" :project_id="project_data.id"></progress-bar>
        <!--// progress-->
        <!-- project amount-->
        <div class="project-amount fs-5 d-flex justify-content-between p-3 gap-3">
          <div
            class="
              total-amount
              d-flex
              justify-content-between
              align-items-center
              edc-border-primary
              rounded-20
              py-1
              px-3
              bg-white
              flex-fill
            "
            v-if="parseInt(project_data.meta.total) >= 0"
          >
            <div
              class="
                project-amount-icon
                bg-white
                p-1
                edc-border-primary
                rounded-10
              "
            >
              <i class="fas fa-coins text-muted"></i>
            </div>
            <div class="project-amount-text me-2 flex-fill">
              <span class="d-block fs-6"
                >{{ $t("label.amount_collected") }}
                {{ project_data.currency }}</span
              >
              <span class="d-block edc-color-green">
                <div class="d-flex">
                  <count-up :endVal="parseInt(project_data.meta.total)">
                  </count-up>
                </div>
              </span>
            </div>
          </div>
          <div
                  v-if="parseInt(project_data.meta.residual) > 0"
            class="
              total-residual
              d-flex
              justify-content-between
              align-items-center
              edc-border-primary
              rounded-20
              py-1
              px-3
              bg-white
              flex-fill
            "
          >
            <div
              class="
                project-amount-icon
                bg-white
                p-1
                edc-border-primary
                rounded-10
              "
            >
              <i class="fas fa-coins text-muted"></i>
            </div>
            <div class="project-amount-text me-2 flex-fill" >
              <span class="d-block fs-6"
                >{{ $t("label.remaining_amount") }}
                {{ project_data.currency }}</span
              >
              <span class="d-block edc-color-green d-flex">
                <count-up :endVal="parseInt(project_data.meta.residual)">
                </count-up>
        
              </span>
            </div>
          </div>
        </div>
        <!--//project amount-->

        <!--project value-->
        <div class="project-value fs-5 d-flex justify-content-between p-3" v-if="parseInt(project_data.meta.target) > 0">
          <div class="project-value-title edc-color-primary fw-bold">
            {{ $t("label.project_value") }}
          </div>
          <div class="project-value-money edc-color-primary fw-bold d-flex">
            <count-up :endVal="parseInt(project_data.meta.target)" >
              {{ project_data.currency }}
            </count-up>
          
            <i class="far fa-money-bill-alt"></i>
          </div>
        </div>
      </div>
      <div class="project-content-left">
        <div v-if="project_data.is_completed === false" class="mt-3">
          <!--//project value-->
          <div class="px-3">
            {{ $t("label.add_amount") }}
          </div>
          <!--project amount list-->
          <div class="d-flex my-2 gap-2 justify-content-center px-3">
            <button
              class="flex-fill btn edc-btn-primary text-white"
              @click.prevent="addAmount(amount)"
              v-for="(amount, index) in project_data.amounts"
              :key="index"
            >
              {{ amount }}
            </button>
          </div>
          <!--//project amount list-->
        </div>
        <!--project price-->
        <div
          class="project-price px-3 pt-2 fs-7"
          v-if="project_data.is_completed === false"
        >
          <div
            class="
              project-price-box
              my-2
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div
              class="
                project-price-box-up-down
                bg-white
                edc-border-primary
                rounded-pill
                d-flex
                align-items-stretch align-self-stretch
              "
            >
              <button
                type="button"
                :data-id="project_data.id"
                @click="priceChange(project_data, '+')"
                aria-label="incress amount"
                class="
                  up
                  border-start border-0
                  bg-transparent
                  edc-color-green
                  px-3
                "
              >
                <i class="fas fa-plus"></i>
              </button>
              <button
                type="button"
                :data-id="project_data.id"
                @click="priceChange(project_data, '-')"
                class="down border-0 bg-transparent edc-color-red px-3"
                aria-label="decress amount"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
            <div
              class="
                project-price-box-input
                d-flex
                flex-fill
                justify-content-between
                align-items-center
                bg-white
                edc-border-primary
                px-3
                py-2
                me-1
                w-75 w-lg-100
                rounded-pill
                align-items-center
              "
            >
              <input
                type="text"
                :id="'price-input-' + project_data.id"
                class="
                  price_input
                  border-0
                  w-100
                  flex-fill
                  fs-5
                  edc-color-green
                  bg-transparent
                  text-center
                "
                placeholder="00.000"
                v-model="amount"
                @keyup="inputChange(amount)"
              />
              <div class="kd fs-4 align-middle">
                {{ project_data.currency }}
              </div>
            </div>
          </div>
        </div>
        <!--//project price-->
        <!--project buttons-->
        <div
          class="project-buttons px-3 mt-2"
          v-if="project_data.is_completed === false"
        >
          <div class="d-grid gap-2">
            <button
              type="button"
              :data-id="project_data.id"
              @click.prevent="fastDonate(amount, project_data)"
              class="
                fast-donate
                btn
                edc-btn-blue
                rounded-pill
                btn-lg btn-block
                py-3
                text-center text-white
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <i class="fas fa-coins mx-3"></i>
              <div class="fs-6">
                <div class="fs-6">{{ $t("btn.fast_donate") }}</div>
              </div>
            </button>
            <button
              type="button"
              id=""
              :data-id="project_data.id"
              @click.prevent="add_to_cart()"
              class="
                addToCart
                btn
                edc-btn-outline-red
                bg-white
                rounded-pill
                btn-lg btn-block
                py-3
                text-center text-muted
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <i class="fas fa-shopping-cart mx-3"></i>
              <span class="fs-6">{{ $t("btn.add_to_cart") }}</span>
            </button>
          </div>
        </div>
        <!--//project buttons-->
      </div>
    </div>
  </div>
</template>
<script>
import ProgressBar from "@/components/ProgressBar";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import vueLazysizes from "vue-lazysizes";
export default {
  name: "Project",
  components: { ProgressBar },
  props: {
    project_data: {
      type: Object,
      deep: true,
    },
  },
  data() {
    return {
      amount: 0,
      gridClass: "col-lg-4 col-md-6",
      display_list: "",
      //amount_class: "edc-btn-blue",
    };
  },
  methods: {
    ...mapActions("cart", ["addToCart", "getCart"]),
    priceChange(item, type) {
      if (type === "+") {
        if (this.amount < 10000) {
          this.amount++;
        } else {
          this.amount = 10000;
        }
      } else if (type === "-") {
        if (this.amount > 1) {
          this.amount--;
        } else {
          this.amount = 1;
        }
      }
    },
    inputChange(amount) {
      if (amount > 10000) {
        this.amount = 10000;
      }
      if (amount < 1) {
        this.amount = 1;
      }
    },
    addAmount(amount) {
      this.amount = amount;
    },
    async add_to_cart() {
      if (this.amount <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_ammount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }
      this.addToCart({ id: this.project_data.id, amount: this.amount })
        .then((response) => {
          //console.log(response);
          //this.getCart();
          this.$swal({
            icon: "success",
            title: this.$t("message.add_to_cart_success"),
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", this.$swal.stopTimer);
              toast.addEventListener("mouseleave", this.$swal.resumeTimer);
            },
          });
        })
        .catch((error) => {
          if (error) {
            this.$swal({
              icon: "error",
              title: this.$t("message.add_to_cart_fail"),
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        });
    },
    fastDonate(amount, project) {
      if (amount <= 0) {
        this.$swal({
          icon: "error",
          title: this.$t("message.must_insert_ammount"),
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", this.$swal.stopTimer);
            toast.addEventListener("mouseleave", this.$swal.resumeTimer);
          },
        });
        return false;
      }
      this.$root.$emit("doFastDonate", { amount: amount, project: project });
    },
    followProject() {
      axios
        .post("add-to-wishlist", { id: this.project_data.id, user_id: 1 })
        .then((response) => {
          if (response.status === 200) {
            //console.log(response.data);
            this.$swal({
              icon: "success",
              title: response.data.message,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
            this.project_data.is_wishlist = response.data.is_follow;
          }
        })
        .catch((error) => {
          if (error) {
            //console.log(error);
            this.$swal({
              icon: "error",
              title: error.response.data.message,
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", this.$swal.stopTimer);
                toast.addEventListener("mouseleave", this.$swal.resumeTimer);
              },
            });
          }
        });
    },
  },
  mounted() {
    //do event update Header ShopCart
    if (localStorage.getItem("project-display") === "row") {
      this.gridClass = "col-lg-12 col-md-12";
      this.display_list = "display-list";
    }
    if (localStorage.getItem("project-display") === "box") {
      this.gridClass = "col-lg-4 col-md-6";
      this.display_list = "";
    }
    this.$root.$on("updateProjectView", (data, grid) => {
      // here you need to use the arrow function
      this.gridClass = data;
      this.display_list = grid;
    });
    this.amount = this.project_data.fixed_amount;
    let _this = this;
    this.$root.$on("updateProjectAmountDetails",function (payload,id) {
        if(_this.project_data !== null && id === _this.project_data.id){
            _this.project_data.meta = payload;
        }
    });
  },
  directives: {
    lazysizes: vueLazysizes
  },
  
};
</script>